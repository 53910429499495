@mixin ps-link-color {
  color: #385623;
  &:visited {
    color: #385623;
  }
}

.top-navigation {
  float: right;
  display: flex;
  margin: 10px 10px;
}

.top-link {
  > a {
    color: #6c6c6c;
    font-size: 1em;
    text-decoration: none;
  }
  &::before {
    content: '|';
    margin: 0 10px;
  }
  &:first-child {
    &::before {
      content: '';
    }
  }
}

.ps-padding-sides {
  padding: 0 20px;
}

.ps-color {
  color: #0a9928;
  margin-top: 0;
}

.ps-title {
  font-size: 2em;
}

.ps-float-right {
  float: right;
}
.ps-float-right > * {
  text-align: right;
}

.ps-contact > p {
  > a {
    @include ps-link-color;
    text-decoration: none;
    &:hover {
      color: #23527c;
      text-decoration: underline;
    }
  }
}

.skill-block {
  display: inline-block;
  margin-right: 10px;
}

.project-section {
  > a {
    text-decoration: none;
  }
  > p {
    margin-top: 0;
  }
}

.work-section {
  > p {
    margin-top: 0;
  }
}

.education-section {
  > p {
    margin-top: 0;
  }
}

.col-sm-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.switchWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.switchWrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}
